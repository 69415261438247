import { GraphQLClient } from 'graphql-request';
import queries from './queries';
import * as mutations from './mutations';

const trimTrailingSlash = str => str.replace(/\/+$/, '');
function buildClient(accountId, options = {}) {
    const baseUrl = trimTrailingSlash(options.baseUrl);
    return new GraphQLClient(`${baseUrl}/${accountId}/graphql`);
}

export default function buildApiClient(accountId, options = {}) {
    const client = buildClient(accountId, options);
    return {
        alternativeNames: [],
        ...queries,
        client,
        mutations
    }
}