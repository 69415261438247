export function getQueryParameters(url) {
    const urlParts = url.split('?');
    let getVars = {};
    if (urlParts.length == 2)
    {
      const vars = urlParts[1].split('&');
      let tmp = '';
      vars.forEach(function(v){
        tmp = v.split('=');
        if(tmp.length == 2)
        getVars[tmp[0]] = tmp[1];
      });
    }
    return getVars;
}