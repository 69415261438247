import { gql } from 'graphql-request';

export default {
    getItemFields: gql`
        query getHostedFormItemFields {
            __type(name:"ItemQueryResponse"){
                fields {
                    name
                }
            }
        }
    `,
    getTyckaDataForTransaction(alternativeNameFields) {
        return gql`
            query getTyckaDataForTransaction(
                $itemId: String!
                $transactionId: String!
                $secret: String
                ) {
                    item(id:$itemId){
                        name
                        ${alternativeNameFields.join('\n')}
                        imageUrls
                        tags
                        fields {
                            name
                            type
                        }
                    }
                    transaction(
                        id: $transactionId
                        secret: $secret) {
                        items
                        customFields {
                            key
                            value
                        }
                        reviewedItems
                        authorId
                    }
                }
    `
    }
}